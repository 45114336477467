import _ from 'lodash';


export const ImportUtil = {

    trimWhiteSpaces: (obj) => {
        let trimedObj = Object.assign({}, obj);
        let keys = Object.keys(trimedObj);

        _.forEach(keys, key => {
            let value = trimedObj[key];
            if (value.length > 0) {
                value = value.trim();
            }
            trimedObj[key] = value;
        });

        return trimedObj;
    },

    hasBlankColumnName: (obj) => {
        let keys = Object.keys(obj);
        for (const key of keys) {
            if (key.trim().length === 0) {
                return true;
            }
        }
        return false;
    }

};
